<template>
  <el-scrollbar class="scrollbar" style="height: calc(100%);background:#F1F1F1;">
    <div style="padding: 10px;">
      <div v-for="(l) in log" :key="l.name">
        <div
          v-if="l.list.length>0"
          style="background: white;border-radius: 6px;margin-bottom: 20px;padding: 45px 10px 10px 10px;position: relative;"
        >
          <div class="title">{{ l.name }}</div>
          <div class="row" style="flex-wrap: wrap;">
            <div
              v-for="(v) in l.list"
              :key="v.md5"
              class="column video"
              @click="onVideoClick(v.id,v.groupingId,v.tag)"
            >
              <el-image
                v-if="v.url.includes('mp3')"
                fit="cover"
                :src="require('@/assets/mp3-logo.jpg')"
                lazy
              />
              <el-image v-else class="img" fit="cover" :src="v.cover" lazy />
              <div v-if="l.name=='更早'" class="finallyTime">{{ v.finallyTime }}</div>
              <div class="column info">
                <label
                  class="grouping"
                >专栏：{{ v.pGrouping==null? `${v.grouping}`:`${v.pGrouping} - ${v.grouping}` }}</label>
                <label class="name">{{ v.name }}</label>
                <label class="lastTime">{{ secondToTimeStr(parseInt(v.lastTime / 1000)) }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-scrollbar>
</template>
<script>
import { playLog } from '@/apis/word-library'
import { secondToTimeStr } from '@/utils'
export default {
  name: 'Playlog',
  components: {},
  props: {},
  data() {
    return {
      today: '',
      yesterDay: '',
      frontDay: '',
      log: [
        { name: '今天', list: [] },
        { name: '昨天', list: [] },
        { name: '前天', list: [] },
        { name: '更早', list: [] }
      ]
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    // 设置日期（今天，昨天，前天）
    var dateTime = new Date()
    this.today = this.formatDate(dateTime, 'yyyy-MM-dd')
    dateTime = dateTime.setDate(dateTime.getDate() - 1)
    dateTime = new Date(dateTime)
    this.yesterDay = this.formatDate(dateTime, 'yyyy-MM-dd')
    dateTime = dateTime.setDate(dateTime.getDate() - 1)
    dateTime = new Date(dateTime)
    this.frontDay = this.formatDate(dateTime, 'yyyy-MM-dd')
    // 拉取数据
    playLog({ index: 1, rows: 1000 }).then(res => {
      res.data.forEach(ele => {
        ele.finallyTime = ele.finallyTime.split(' ')[0]
        if (ele.finallyTime === this.today) {
          this.log[0].list.push(ele)
        } else if (ele.finallyTime === this.yesterDay) {
          this.log[1].list.push(ele)
        } else if (ele.finallyTime === this.frontDay) {
          this.log[2].list.push(ele)
        } else {
          this.log[3].list.push(ele)
        }
      })
    })
  },
  methods: {
    secondToTimeStr,
    // 日期格式化函数
    formatDate(date, fmt) {
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      }
      const o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      }
      for (const k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          const str = o[k] + ''
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          )
        }
      }
      return fmt
    },
    // 日期格式化函数
    padLeftZero(str) {
      return ('00' + str).substr(str.length)
    },
    // 视频点击事件
    onVideoClick(id, grouping, tag) {
      this.$router.push({
        path: '/player',
        query: {
          g: grouping,
          v: id,
          t: tag,
          dir: `PALYLOG`
        }
      })
    }
  }
}
</script>
<style lang='less' scoped>
.scrollbar {
  // height: 100%;
  margin-bottom: 50px;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}
.title {
  background: #c61200;
  color: white;
  font-size: 14px;
  padding: 6px 20px;
  position: absolute;
  left: 0px;
  top: 10px;
  border-radius: 0px 6px 6px 0px;
}
.video {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  height: 150px;
  width: 250px;
  background: rgb(49 49 49);
  margin: 10px;
  .img {
    height: 150px;
  }
  .finallyTime {
    position: absolute;
    left: 0px;
    top: 10px;
    background: #c61200;
    color: white;
    font-size: 12px;
    padding: 0px 5px;
    border-radius: 0px 6px 6px 0px;
  }
  .info {
    position: absolute;
    bottom: 0px;
    background: rgba(0, 0, 0, 0.771);
    width: 100%;
    .grouping {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;
      color: rgb(194, 194, 194);
      margin-left: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 12px;
      color: white;
      margin-left: 5px;
      margin-bottom: 5px;
    }
    .lastTime {
      position: absolute;
      right: 5px;
      top: 5px;
      color: rgb(141, 141, 141);
      font-size: 12px;
    }
  }
}
</style>
